<template>
  <div class="container">
    <h3>{{ $t("market.historyOrders") }}</h3>
    <div class="operTab">
      <el-radio-group v-model="operActive" size="medium" @input="handleClick">
        <el-radio-button label="1">{{ $t("menu.contract") }}</el-radio-button>
        <el-radio-button label="2">{{
          $t("menu.secondContract")
        }}</el-radio-button>
      </el-radio-group>
      <!-- <el-tabs v-model="operActive" type="card" @tab-click="operClick">
        <el-tab-pane :label="$t('menu.contract')" name="1"></el-tab-pane>
        <el-tab-pane :label="$t('menu.secondContract')" name="2"></el-tab-pane>
      </el-tabs> -->
    </div>
    <div class="tabsBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('market.digitalCurrency')"
          name="1"
        ></el-tab-pane>
        <el-tab-pane :label="$t('market.nobleMetal')" name="6"></el-tab-pane>
        <el-tab-pane
          :label="$t('market.foreignExchange')"
          name="5"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :empty-text="$t('rankText.emptyText')"
    >
      <!-- 名称 -->
      <el-table-column prop="name" :label="$t('rankText.name')" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.symbol || scope.row.product_name || scope.row.symbol_name
          }}</span>
        </template>
      </el-table-column>
      <!-- 状态 -->
      <el-table-column prop="num" align="center" :label="$t('market.status')">
        <template slot-scope="scope">
          <span
            class="buyStatusSpan"
            :class="scope.row.type == '1' ? 'redBg' : 'greenBg'"
            >{{
              scope.row.type == "1"
                ? $t("market.buyMore")
                : $t("market.buyEmpty")
            }}</span
          >
        </template>
      </el-table-column>
      <!-- 下单数量 -->
      <el-table-column
        prop="name"
        :label="$t('market.orderNumber')"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ toSelfFixed(scope.row.number, 2) }}</span>
        </template>
      </el-table-column>
      <!-- 开仓价 -->
      <el-table-column
        prop="num"
        align="center"
        :label="$t('market.openHousePrice')"
      >
        <template slot-scope="scope">
          <span>{{
            toSelfFixed(
              scope.row.open_price ? scope.row.open_price : scope.row.price,
              4
            )
          }}</span>
        </template>
      </el-table-column>
      <!-- 当前价 -->
      <el-table-column prop="num" align="center" :label="$t('market.nowPrice')">
        <template slot-scope="scope">
          <span>{{
            toSelfFixed(
              scope.row.end_price
                ? scope.row.end_price
                : scope.row.update_price,
              4
            )
          }}</span>
        </template>
      </el-table-column>
      <!-- 盈亏 -->
      <el-table-column
        prop="num"
        align="center"
        :label="$t('market.profitLoss')"
      >
        <template slot-scope="scope">
          <span :class="scope.row.fact_profits > 0 ? 'red' : 'green'">{{ toSelfFixed(scope.row.fact_profits, 2) }}</span>
        </template>
      </el-table-column>
      <!-- 买入时间 -->
      <el-table-column prop="num" align="center" :label="$t('market.buyTime')">
        <template slot-scope="scope">
          <template v-if="scope.row.create_time">
            <span>{{
              dayjs(scope.row.create_time * 1000).format("YYYY/MM/DD HH:mm:ss")
            }}</span>
          </template>
          <template v-else>
            <span>{{ scope.row.created_at }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 完成时间 -->
      <el-table-column
        prop="num"
        align="center"
        :label="$t('market.finshTime')"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.handle_time || scope.row.handled_at }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagContainer">
      <el-pagination
        background
        @current-change="initData"
        :current-page.sync="postData.page"
        :page-size="postData.limit"
        layout="total, prev, pager, next"
        :total="postData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { toSelfFixed } from "@/utils/common";
import dayjs from "dayjs";
import {
  get_wh_trade,
  get_my_trade,
  getwhlistsTable,
  getNumLists,
} from "@/api/market";
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
const operActive = ref("1");
// const operClick = () => {
//   postData.value.page = 1;
//   postData.value.limit = 10;
//   postData.value.total = 0;
//   tableData.value = [];
//   initData();
// };
const activeName = ref("1");
const handleClick = () => {
  postData.value.page = 1;
  postData.value.limit = 10;
  postData.value.total = 0;
  tableData.value = [];
  initData();
};
const postData = ref({
  page: 1,
  limit: 10,
  total: 0,
});
const tableData = ref([]);
const initData = async () => {
  tableData.value = [];
  if (operActive.value == "1") {
    if (activeName.value == "1") {
      const { message } = await get_my_trade({
        status: "3",
        legal_id: "3",
        limit: postData.value.limit,
        page: postData.value.page,
        lang: lang.value,
      });
      tableData.value = message.message.data;
      postData.value.total = message.message.total;
    } else {
      const { message } = await get_wh_trade({
        type: activeName.value,
        status: "3",
        limit: postData.value.limit,
        page: postData.value.page,
        lang: lang.value,
      });
      tableData.value = message.data;
      postData.value.total = message.total;
    }
  } else {
    if (activeName.value == "1") {
      const { message } = await getNumLists({
        status: 3,
        limit: postData.value.limit,
        page: postData.value.page,
      });
      tableData.value = message.data;
      postData.value.total = message.total;
    } else {
      const { message } = await getwhlistsTable({
        status: 3,
        cid: activeName.value,
        limit: postData.value.limit,
        page: postData.value.page,
      });
      tableData.value = message.data;
      postData.value.total = message.total;
    }
  }
};

onMounted(() => {
  initData();
});
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
  margin: 35px auto;
  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .operTab {
    background-color: $section-color;
    padding: 10px 0 0 20px;
    box-sizing: border-box;
  }
  .tabsBox {
    background-color: $section-color;
    padding: 0 0 0px 20px;
    box-sizing: border-box;
  }
  .buyStatusSpan {
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
  }
  .pagContainer {
    padding: 10px 10px 10px 50px;
    background-color: $section-color;
  }
}
</style>
